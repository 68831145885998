<template>
	<edit-template class="add-grade" @confirm="saveData" @cancel="cancelData" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem">
        <el-form-item label="毕业年份" prop="name">
          <el-select :popper-append-to-body="false" v-model="form.name" placeholder="请选择" class="select">
            <el-option v-for="item in gradeList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入学年份" prop="enrollment">
          <el-select :popper-append-to-body="false" v-model="form.enrollment" placeholder="请选择" class="select">
            <el-option v-for="item in classList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入学时间" format="HH:mm:ss" prop="admission_time">
          <el-date-picker v-model="form.admission_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择"> </el-date-picker>
        </el-form-item>
        <el-form-item label="开学时间" format="HH:mm:ss" prop="opening_time">
          <el-date-picker v-model="form.opening_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择"> </el-date-picker>
        </el-form-item>
        <el-form-item label="毕业时间" prop="graduation_time">
          <el-date-picker v-model="form.graduation_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择"> </el-date-picker>
        </el-form-item>
      </el-form>
    </div>


</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      form: {
        name: '',  //毕业年份
        enrollment: '',  //入学年份
        admission_time: '',  //入学时间
        opening_time: '',  //开学时间
        graduation_time: ''  //毕业时间
      },
      rules: {
        name: [{ required: true, message: '请输入毕业年份', trigger: 'change' },],
        enrollment: [{ required: true, message: '请输入入学年份', trigger: 'change' },],
        opening_time: [{ required: true, message: '请输入开学时间', trigger: 'change' },],
        admission_time: [{ required: true, message: '请选择入学时间', trigger: 'change' }],
        graduation_time: [{ required: true, message: '请选择毕业时间', trigger: 'change' }],
      },
      gradeList: [],
      classList: [],
      value: '',
      value1: '',
      value2: ''
    }
  },

  created() {
    this.getYear()
  },
  methods: {
    //计算毕业年份
    getYear(){
      let year = new Date().getFullYear()
      for (let i = 0; i < 5; i++) {
        let str1 = year + '届'
        let str2 = year + '级'
        this.gradeList.push(str1)
        this.classList.push(str2)
        year++
      }
    },

    saveData() {
      this.$refs.form.validate((valid) => {
        let params = {...this.form}
        if (valid) {
          this.$_axios.post('/setup/grade-add',{...params}).then(res => {
            this.$message.success('新增成功')
            this.$router.back()
          })
        } else {
          return false;
        }
      });
    },

    cancelData() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
